





































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import DashboardGrid from '../../components/dashboard/dashboard-grid.vue';
import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';
import { ResultModule } from '../../store/modules/result-module';
import { downloadJson, readJson } from '../../utils/download';

@Component({
  components: { DashboardGrid, ResearchTargetTabs },
})
export default class AdminGlobalDashboard extends Vue {
  public result = getModule(ResultModule);

  async created() {
    await this.result.bind();
  }

  public async devExportModules() {
    const data = { ...this.result.dashboardLayout };
    downloadJson(data, 'dashboard.json');
  }

  public async devImportModules() {
    const data = await readJson();
    const confirm = await this.$bce.confirm(
      'Let op!',
      'De huidige layout wordt vervangen, weet je zeker dat je door wilt gaan?',
      { cancel: 'Annuleren', ok: 'Vervang Layout' }
    );
    if (confirm) await this.$firebase.doc('app/dashboard').set(data);
  }

  public async update(target: string, layout: any) {
    await this.$firebase.doc('app/dashboard').set({
      ...this.result.dashboardLayout,
      [target]: layout,
    });
  }
}
